<template>
    <div>
        <card-header title="Search Customers" icon="fa-users"/>

        <card-body>
            <card-list>
                <subheader-list-item :title="props.query" icon="fa-align-left" :badge="results.count + ' Results'"/>

                <user-list-item
                    :card="card"
                    :user="u"
                    v-for="u in results.users"
                    :active="child && child.definition.component === 'customer' && child.props.userId === u.id.toString()"
                    @click="$openCard('customer', {userId: u.id}, card);"
                />
            </card-list>
        </card-body>
    </div>
</template>

<script>
    import CardHeader from "../../TIER/components/CardHeader";
    import CardList from "../../TIER/components/CardList";
    import CustomerListItem from "../../components/CustomerListItem";
    import CardToolbar from "../../TIER/components/CardToolbar";
    import SearchListItem from "../../TIER/components/SearchListItem";
    import CardBody from "@/TIER/components/CardBody";
    import SubheaderListItem from "@/TIER/components/SubheaderListItem";
    import {client as http} from "@/http_client";
    import UserListItem from "@/components/CustomerListItem";
    import async from "async";

    export default {
        name: "Users",
        props: ['card', 'child', 'props'],
        components: {UserListItem, SubheaderListItem, CardBody, SearchListItem, CardToolbar, CustomerListItem, CardList, CardHeader},
        data: function() {
            return {
                results: {
                    count: 0,
                    users: []
                }
            };
        },
        methods: {
            loadCustomers(force) {
                this.$emit('loading', true);
                http.get('/api/users/search/' + this.props.query + '/0/30', {force}).then(response => {
                    for (let i = 0; i < response.data.length; i++) {
                        response.data[i].active = false;
                    }
                    this.results = response.data;
                    this.$emit('loading', false);
                });
            },
            reload: function() {
                this.loadCustomers(true);
            }
        },
        watch: {
            'props.query': function() {
                this.loadCustomers(true);
            }
        },
        mounted() {
            this.loadCustomers();
        }
    };
</script>

<style lang="scss" scoped>

</style>
