<template>
    <card-list-item @click="$emit('click')" :class="{'active': active}">
        <template v-slot:icon><i class="fa-fw fa-user" :class="{'fas': active, 'far': !active}"></i></template>
        <template v-slot:content>
            <div class="user-title">{{user.last_name}}, {{user.first_name}}</div>
            <div class="meta" v-if="user.email"><i class="far fa-fw fa-envelope mr-2"></i>{{user.email}}</div>
        </template>
        <template v-slot:status>
            <div class="stats">
                <span class="stat" v-if="user.registrations && user.registrations.length"><i class="far fa-suitcase"></i>{{user.registrations.length}}</span>
            </div>
        </template>
        <template v-slot:details>
            <div class="data-table">
                <toolbar class="toolbar">
                    <button class="icon-only" @click.stop="login"><i class="far fa-sign-in"></i></button>
                    <button class="icon-only" @click.stop="$openCard('customer', {userId: user.id}, card)"><i class="far fa-user"></i></button>
                    <button class="icon-only" @click.stop="$openCard('customer-notes', {userId: user.id}, card)"><i class="far fa-sticky-note"></i></button>
                    <button class="icon-only" @click.stop="$openCard('customer-documents', {userId: user.id}, card)"><i class="far fa-id-card"></i></button>
                    <button class="icon-only" @click.stop="$openCard('edit-customer', {userId: user.id}, card)"><i class="far fa-pencil"></i></button>
                </toolbar>

                <div class="data mt-1">
                    <data-row mini title="Preferred">{{user.preferred_name}}</data-row>
                    <data-row mini title="E-Mail">{{user.email}}</data-row>
                    <data-row mini title="Phone">{{user.phone}}</data-row>
                    <data-row mini title="Created">{{user.created_at | moment('MMM Do, YYYY, h:mm a')}}</data-row>
                    <data-row mini title="Updated" v-if="user.updated_at">{{user.updated_at | moment('MMM Do, YYYY, h:mm a')}}</data-row>
                    <data-row mini title="Accessed" v-if="user.accessed_at">{{user.accessed_at | moment('MMM Do, YYYY, h:mm a')}}</data-row>
                    <data-row mini title="Auth.net">{{user.authnet_customer_profile_id}}</data-row>
                    <data-row mini title="ID">{{user.id}}</data-row>
                </div>
            </div>
        </template>
    </card-list-item>
</template>

<script>
    import Toolbar from "../TIER/components/Toolbar";
    import DataRow from "../TIER/components/DataRow";
    import CardListItem from "../TIER/components/CardListItem";

    export default {
        name: 'UserListItem',
        components: {CardListItem, DataRow, Toolbar},
        props: {
            card: {
                required: true
            },
            user: {
                type: Object,
                required: true
            },
            active: {
                type: Boolean,
                default: false
            }
        },
        data: function () {
            return {
                details: false
            }
        }
    }
</script>

<style lang="scss" scoped>
    li {
        color: darken($t-primary, 10);

        .user-title {
            font-size: .9rem;
            font-weight: 700;
            margin-top: 1px;
        }

        padding: 0.5rem 0;

        .data-table {
            margin-top: 0.5rem;
        }
    }

</style>
